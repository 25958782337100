import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkBlueSm } from '/features/buildingBlocks/Button'
import { Image } from '/features/buildingBlocks/Image'

import styles from './BenefitsBanner.css'

export function BenefitsBanner({ benefits }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const moreBenefitsPages = {
    nl: routeMap.app.page({ language: 'nl', slug: 'meer-redenen-om-voor-klm-te-kiezen' }),
    en: routeMap.app.page({ language: 'en', slug: 'more-reasons-to-choose-klm' })
  }

  return (
    <section data-x='job-benefits' className={styles.component}>
      <div className={styles.bannerHeader}>
        <HeadingMd h='2'>{__`explore-the-benefits`}</HeadingMd>
        <ButtonLinkBlueSm label={__`more-benefits`} href={moreBenefitsPages[language]} dataX='link-to-benefits' target='_self' />
      </div>
      <BenefitsCards layoutClassName={styles.benefitsCardsLayout} {...{ benefits }} />
    </section>
  )
}

function BenefitsCards({ benefits, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentBenefitsCards, layoutClassName)}>
      {benefits.map((item, i) => (
        <BenefitCard key={i} layoutClassName={styles.cardLayout} {...{ item }} />
      ))}
    </div>
  )
}

function BenefitCard({ item, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentBenefitCard, layoutClassName)}>
      <Image image={item.iconImage} layoutClassName={styles.iconLayout} />
      <p className={styles.label}>{item.title}</p>
    </div>
  )
}
