export function useTargetAndRel({ href, targetOverride = undefined }) {
  if (targetOverride) {
    return {
      target: targetOverride,
      rel: targetOverride === '_blank' ? 'noopener noreferrer' : null
    }
  }

  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const targetProtocol = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return { target: targetProtocol, rel }
}
