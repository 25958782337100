import { useTargetAndRel } from '/machinery/useTargetAndRel'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

import arrowRightIcon from '/images/icons/arrow-right.raw.svg'
import arrowLeftIcon from '/images/icons/arrow-left.raw.svg'

export function ButtonBlue({ onClick, dataX, label, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentBlue, layoutClassName)} {... { label, dataX, onClick }} />
}

export function ButtonBlueReverse({ onClick, dataX, label, layoutClassName = undefined }) {
  return <ButtonReverseBase className={cx(styles.componentBlueReverse, layoutClassName)} {... { label, dataX, onClick }} />
}

export function ButtonWhite({ onClick, dataX, label, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentWhite, layoutClassName)} {... { label, dataX, onClick }} />
}

export function ButtonLinkBlue({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return  <ButtonLinkBase className={cx(styles.componentLinkBlue, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkBlueReverse({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return  <ButtonLinkReverseBase className={cx(styles.componentLinkBlueReverse, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkBlueSm({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return  <ButtonLinkBase className={cx(styles.componentLinkBlueSm, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkWhite({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkWhite, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkWhiteXs({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkWhiteXs, layoutClassName)} {... { href, dataX, label, target }} />
}

function ButtonBase({ onClick, dataX, label, className }) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentBase, className)}
      {...{ onClick }}
    >
      <span>{label}</span>
      <span className={styles.icon}>
        <Icon icon={arrowRightIcon} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function ButtonReverseBase({ onClick, dataX, label, className }) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentReverseBase, className)}
      {...{ onClick }}
    >
      <span>{label}</span>
      <span className={styles.icon}>
        <Icon icon={arrowLeftIcon} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )
}

function ButtonLinkBase({ href, dataX, label, className, target: targetOverride }) {
  const { target, rel } = useTargetAndRel({ targetOverride, href })

  return (
    <a
      aria-label={label}
      data-x={dataX}
      className={cx(styles.componentLinkBase, className)}
      {...{ href, target, rel }}
    >
      <span>{label}</span>
      <span className={styles.icon}>
        <Icon icon={arrowRightIcon} layoutClassName={styles.iconLayout} />
      </span>
    </a>
  )
}

function ButtonLinkReverseBase({ href, dataX, label, className, target: targetOverride }) {
  const { target, rel } = useTargetAndRel({ targetOverride, href })

  return (
    <a
      aria-label={label}
      data-x={dataX}
      className={cx(styles.componentLinkReverseBase, className)}
      {...{ href, target, rel }}
    >
      <span>{label}</span>
      <span className={styles.icon}>
        <Icon icon={arrowLeftIcon} layoutClassName={styles.iconLayout} />
      </span>
    </a>
  )
}
